<link href="search-bar.component.scss" rel="stylesheet" />
<div class="search-bar">
  <img
    alt="Loupe de recherche"
    height="20px"
    src="assets/icons/search.svg"
    width="20px" />

  <app-input-slide
    (keyup)="onSearch()"
    [(ngModel)]="term"
    [forDesktop]="true"
    icon="right"
    inputType="text"
    placeholder="Recherche"
    width="fit" />
</div>
