import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatEuro',
  standalone: true,
})
export class FormatEuroPipe implements PipeTransform {
  transform(value: number | string): string {
    let formattedNumber = '';
    if (value === null || value === undefined || value === 0) {
      return '-';
    }

    if (typeof value === 'number') {
      formattedNumber = value.toString();
    } else {
      formattedNumber = value;
    }

    // Formatage du nombre avec des points comme séparateurs de milliers
    formattedNumber = formattedNumber.replace(/\B(?=(\d{3})+(?!\d))/g, '.');

    // Ajouter le symbole de l'euro à la fin
    return formattedNumber + ' €';
  }
}
