import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatEuroWithNull',
  standalone: true,
})
export class FormatEuroWithNullPipe implements PipeTransform {
  transform(value: number | string): string | number {
    if (this.isEmptyValue(value)) {
      return '-';
    }
    return this.formatNumberValue(value);
  }

  private isEmptyValue(value: number | string): boolean {
    return (
      value === null ||
      value === undefined ||
      value === 0 ||
      value === '0' ||
      value === ''
    );
  }

  private formatNumberValue(value: number | string): string {
    const formattedNumber =
      typeof value === 'number' ? value.toString() : value;
    return this.formatAsEuro(this.addThousandSeparators(formattedNumber));
  }

  private addThousandSeparators(value: string): string {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  }

  private formatAsEuro(value: string): string {
    return `${value} €`;
  }
}
