import { Pipe, PipeTransform } from '@angular/core';
import { legalFormObject } from '@src/shared/legal-form/objects/legal-form.objects';

@Pipe({
  name: 'idToLegalForm',
  standalone: true,
})
export class IdToLegalFormPipe implements PipeTransform {
  transform(value: number): string {
    const activity = legalFormObject.find(item => item.id === value);
    return activity ? activity.name : '-';
  }
}
