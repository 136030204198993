import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  Output,
  Renderer2,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => SearchBarComponent),
    },
  ],
})
export class SearchBarComponent implements ControlValueAccessor {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public onTouched: any;
  public isDisabled = false;
  @Output() search = new EventEmitter<string>();
  @Input() placeholder: string = 'Recherche...';
  @Input() forDesktop: boolean = false;
  term: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private onChange: any;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private cdr: ChangeDetectorRef
  ) {}

  public writeValue(value: string) {
    this.term = value;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onSearch(): void {
    this.search.emit(this.term);
    this.cdr.detectChanges();
  }

  onFocus(): void {
    const searchBar = this.el.nativeElement.querySelector('.search-bar');
    this.renderer.addClass(searchBar, 'active');
  }

  onBlur(): void {
    if (!this.term) {
      const searchBar = this.el.nativeElement.querySelector('.search-bar');
      this.renderer.removeClass(searchBar, 'active');
    }
  }

  clear(): void {
    this.term = '';
    this.search.emit(this.term);
    this.onBlur();
  }
}
